import { defineNuxtPlugin } from '#imports'
//@ts-expect-error: virtual file
import { options } from '#nuxt-markdownit-options'
const handlePlugin = (plugin) => plugin.default || plugin

export default defineNuxtPlugin(async (nuxtApp) => {
    const MarkdownIt = await import('markdown-it').then(md => md.default || md)
    let pluginOptions = options
    let preset = pluginOptions.preset || 'default'
    delete pluginOptions.preset
    delete pluginOptions.use

    if (Object.keys(pluginOptions).length === 0) pluginOptions = undefined;

    const md = new MarkdownIt(preset, pluginOptions)

    
    nuxtApp.provide('md', md)
})
